import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { ButtonIcon } from '../appearence/icons/button_icon';
import { LogoIcon } from '../appearence/icons/logo_icon';
import {ELSI_API_URL, errorCallback, makeUrl} from "../constant";
import { LOGIN_REQUEST, postFetchRequest } from '../store/requests';

const LoginPage = ({setAccessToken}) => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const succesLoginCallback = (response) => {
        if(response.token) {
            const { token } = response;
            localStorage.setItem('token', token);
            navigate("/");
            setAccessToken(token);
            return;
        }
    };

    const handleLogin = () => {
        if(login && password) {
            const data = {login, password};
            postFetchRequest(makeUrl([LOGIN_REQUEST]), data, succesLoginCallback, errorCallback);
        }
    };

    const handleKeyDown = (e) => {
        if(e.keyCode === 13) {
                handleLogin();
        }
    };

    return (
        <>
            <header className="login-header">
                <LogoIcon />
            </header>
            <main className="login-main" onKeyDown={handleKeyDown}>
                <div className="login-card">
                    <label className="login-card__label login-card__label"
                        value={login}
                        onChange={(e)=> setLogin(e.target.value.trim())}>Введите e-mail для входа:
                        <input className="login-card__input" />
                    </label>
                    <label className="login-card__label"
                        value={password}
                        onChange={(e)=> setPassword(e.target.value.trim())}>Пароль:
                        <input type="password" className="login-card__input" />
                    </label>
                    <button className="button login-main__button" onClick={handleLogin}>
                        <span className="button__text">Войти </span>
                        <ButtonIcon />
                    </button>
                </div>
            </main>
        </>
    );
};

export default LoginPage;