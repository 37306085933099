import React, { useEffect, useState } from 'react';
import { getFetchRequest, PRODUCT_INFO, REQUEST_INFO } from '../store/requests';
import { makeUrl, errorCallback } from '../constant';
import { ErrorHandler } from './ErrorHandler';

const RequestsTab = ({ isCollapsed }) => {
    const [requestsList, setRequestsList] = useState([]);  
    
    const successGetProductInfoCallback = (response, responseData) => {
        const coppiedArray = [...responseData];
        coppiedArray.forEach(it =>{
            if(it.requestProductId===response.id) {
                it.partNumber = response.partNumber;
                it.manufacturer = response.manufacturer;
            }
        });
        setRequestsList(coppiedArray);
    };

    const succesGetRequestCallback = (result) => {
        if(result.length) {
            for(let i=0; i< result.length; i++) {
                getFetchRequest(makeUrl([PRODUCT_INFO, result[i].requestProductId, '/']), (response)=>successGetProductInfoCallback(response, result), errorCallback);
            } 
        }
    };

    useEffect(() => {
        getFetchRequest(makeUrl([REQUEST_INFO]), succesGetRequestCallback, errorCallback);
    },[]);


    try {
        return (
            <section className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} requests-page`}>
                {requestsList.length?
                    <>
                        <h1 className="requests-page__title">Запросы</h1>
                        <table className="table orders__table">
                            <thead>
                                <tr className="table-header">
                                    <th className="table-header__item">Номер запроса</th>
                                    <th className="table-header__item">Id пользователя</th>
                                    <th className="table-header__item">Email</th>
                                    <th className="table-header__item">Тип запроса</th>
                                    <th className="table-header__item">Парт-номер</th>
                                    <th className="table-header__item">Производитель</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requestsList.map((it, index) => {
                                    return (
                                        <tr key={it.id} className="table-row">
                                            <td className={`table__item ${it.id?'table__item_active':''}`}>{it.id}</td>
                                            <td className={`table__item ${it.userId?'table__item_active':''}`}>{it.userId}</td>
                                            <td className={`table__item ${it.userEmail?'table__item_active':''}`}>{it.userEmail}</td>
                                            <td className={`table__item ${it.requestType?'table__item_active':''}`}>{it.requestType}</td>
                                            <td className={`table__item ${it.partNumber?'table__item_active':''}`}>{it.partNumber}</td>
                                            <td className={`table__item ${it.manufacturer?'table__item_active':''}`}>{it.manufacturer}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </>:<div className='requests-page__empty'>Ничего не найдено</div>}
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default RequestsTab;