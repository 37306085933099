import React, { useEffect, useState } from "react";
import { LinkIcon } from '../appearence/icons/link_icon';
import { errorCallback, makeUrl } from "../constant";
import { getFetchRequest, GET_CATEGORIES } from "../store/requests";

const SELECT_CATEGORY = "Выберите категорию";

const findActiveOption = (option, listOfOption) => {
    if (listOfOption.length) {
        if (listOfOption.find(it => it.categoryId === option.categoryId)) {
            return true
        }
    }
    return false;
};

const CategoriesList = ({ productCategoriesList, setProductCategoriesList, categoriesListIsOpen, setCategoriesListIsOpen }) => {
    const [categoriesList, setCategoriesList] = useState([]);
    const successGetCategoriesCallback = (response) => {
        if(response.length) {
            setCategoriesList(response);
        }
    };

    useEffect(() => {
        getFetchRequest(makeUrl([GET_CATEGORIES]), successGetCategoriesCallback, errorCallback);
    }, []);

    const handleListOpen = (e) => {
        e.stopPropagation();
        setCategoriesListIsOpen(!categoriesListIsOpen);
    };
    const handleOptionClick = (e, value, parentCategory=null) => {
        e.stopPropagation();
        if (parentCategory === null) {
            setProductCategoriesList([{categoryName: value.categoryName, categoryId: value.categoryId}]);
        } else {
            const productCategoriesSelected = [];
            productCategoriesSelected.push(productCategoriesList[0]);
            productCategoriesSelected.push({categoryName: value.categoryName, categoryId: value.categoryId});
            setProductCategoriesList(productCategoriesSelected);
            setCategoriesListIsOpen(false);
        }
    };

    return (
        <div className='custom-select__container products__select'>
            <div
                className="custom-select__text"
                onClick={handleListOpen}
            >
                {SELECT_CATEGORY}
                <LinkIcon nameOfClass="custom-select__logo navigation-link" />
            </div>
            {categoriesListIsOpen && (
                <ul className="custom-select__options modal-select__options">
                    {categoriesList.map(option => {
                        return (
                            <li
                                className={`custom-select__option ${findActiveOption(option, productCategoriesList) ? 'custom-select__option_active' : ''}`}
                                key={option.categoryId}
                                onClick={(e) => handleOptionClick(e, option)}
                            >
                                {option.categoryName}
                                {findActiveOption(option, productCategoriesList) ? option.subCategories.map(item => {
                                    return (
                                        <p
                                            className={`custom-select__option custom-select_suboption ${findActiveOption(item, productCategoriesList) ? 'custom-select__option_active' : ''}`}
                                            key={item.categoryId}
                                            onClick={(e) => handleOptionClick(e, item, option)}
                                        >
                                            {item.categoryName} </p>
                                    )
                                }) : ''}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default CategoriesList;