import React, { useState, useEffect } from 'react';

import { getFetchRequest, GET_STATISTIC } from '../store/requests';
import { STATISTIC_TAB_CATEGORY, STATISTIC_TAB_PRODUCT, errorCallback, makeUrl } from '../constant';
import { Pagination } from './Pagination';
import { handleTransformDate, DEFAULT_LIMIT_STATISTIC, DEFAULT_OFFSET } from './sharedComponents/helper';

const StatisticProductTable = ({statisticProduct}) => (
    <table className="table statistic__table">
        <thead>
            <tr className="table-header">
                <th className="table-header__item">Дата</th>
                <th className="table-header__item">Парт-номер</th>
                <th className="table-header__item">Ресурс</th>
            </tr>
        </thead>
        <tbody>
            {statisticProduct.map((it, index) => {
                return (
                    <tr key={it.searchValue+index} className="table-row">
                        <td className={`table__item ${it.date?'table__item_active':''}`}>{handleTransformDate(it.date)}</td>
                        <td className={`table__item ${it.searchValue?'table__item_active':''}`}>{it.searchValue}</td>
                        <td className={`table__item ${it.source?'table__item_active':''}`}>{it.source}</td>
                    </tr>
                );
            })}
        </tbody>
    </table>
);

const StatisticCategoryTable = ({statisticCategory}) => (
    <table className="table statistic__table">
        <thead>
            <tr className="table-header">
                <th className="table-header__item">Дата</th>
                <th className="table-header__item">Название категории</th>
                <th className="table-header__item">Ресурс</th>
            </tr>
        </thead>
        <tbody>
            {statisticCategory.map((it, index) => {
                return (
                    <tr key={it.searchValue+index} className="table-row">
                        <td className={`table__item ${it.date?'table__item_active':''}`}>{handleTransformDate(it.date)}</td>
                        <td className={`table__item ${it.searchValue?'table__item_active':''}`}>{it.searchValue}</td>
                        <td className={`table__item ${it.source?'table__item_active':''}`}>{it.source}</td>
                    </tr>
                );
            })}
        </tbody>
    </table>
);

const StatisticPopularTable = ({listOfPopular, type}) => (
    <table className="table statistic__table statistic__table-popular">
        <thead>
            <tr className="table-header">
                <th className="table-header__item">Количество запросов</th>
                <th className="table-header__item">{type===STATISTIC_TAB_PRODUCT?'Парт-номер':'Название категории'}</th>
            </tr>
        </thead>
        <tbody>
            {listOfPopular.sort((a,b) => b.count - a.count).map((it, index) => {
                return (
                    <tr key={it.searchValue+index} className="table-row">
                        <td className={`table__item ${it.count?'table__item_active':''}`}>{it.count}</td>
                        <td className={`table__item ${it.searchValue?'table__item_active':''}`}>{it.searchValue}</td>
                    </tr>
                );
            })}
        </tbody>
    </table>
);

const StatisticTab = ({ isCollapsed }) => {
    const [activeTab, setActiveTab] = useState(STATISTIC_TAB_PRODUCT);
    const [statisticCategory, setStatisticCategory] = useState([]);
    const [statisticProduct, setStatisticProduct] = useState([]);
    const [pagesNumber, setPagesNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [count, setCount] = useState(DEFAULT_LIMIT_STATISTIC);
    const [popularListIsOpen, setPopularListIsOpen] = useState(false);
    const [popularRequestsList, setPopularRequestsList] = useState({list: [], type:'', fullList:[]});

    const succesCallback = (response, params) => {
        const productData = [];
        const categoryData = [];
        if(popularListIsOpen) {
            setPopularRequestsList({list: response.slice((params.offset-1)*params.limit, params.offset*params.limit), type: activeTab, fullList: response});
            setPagesNumber(response.length%params.limit===0?response.length/params.limit:Math.ceil(response.length / params.limit));
            setCurrentPage(params.offset);
        } else {
            response.items.forEach(it => it.statsType==="product"?productData.push(it):categoryData.push(it));
            setStatisticCategory(categoryData);
            setStatisticProduct(productData);
            setPagesNumber(response.pagesMax);
            setCurrentPage(response.page);
        }
    };

    useEffect(()=> {
        handleGetData(DEFAULT_OFFSET);
    },[activeTab, popularListIsOpen]);

    const handleGetData = (pageNum, countItem=null) => {
        const params = { offset: pageNum };
        if(countItem) {
            params.limit = countItem;
            setCount(countItem);
            if(popularListIsOpen) {
                params.offset=DEFAULT_OFFSET;
            }
        } else {
            params.limit = count;
        }
        if(activeTab === STATISTIC_TAB_CATEGORY) {
            params.type = 'category';
        }
        if(popularListIsOpen&&popularRequestsList.type===activeTab) {
            succesCallback(popularRequestsList.fullList, params);
        } else {
            getFetchRequest(makeUrl([GET_STATISTIC, popularListIsOpen?'popular/?':'?', new URLSearchParams(params).toString()]), 
                (response) => succesCallback(response, params), errorCallback);
        }
    };

    return (
        <section className={`workspace ${isCollapsed===null ? '': isCollapsed? 'workspace_collapsed' : 'workspace_full-width'}`}>
            <div className="statistic-tab">
                <ul className={`statistic__list ${popularListIsOpen? 'statistic__list_short':''}`}>
                    <li className={`statistic__item ${activeTab===STATISTIC_TAB_PRODUCT? 'statistic__item_active':''} navigation-link`} onClick={() => setActiveTab(STATISTIC_TAB_PRODUCT)}>Продукты</li>
                    <li className={`statistic__item ${activeTab===STATISTIC_TAB_CATEGORY? 'statistic__item_active':''} navigation-link`} onClick={() => setActiveTab(STATISTIC_TAB_CATEGORY)}>Категории</li>
                </ul>
                <div className={`tab-content ${popularListIsOpen? 'statistic-tab_short':''}`}>
                    {popularListIsOpen ? 
                        <StatisticPopularTable listOfPopular={popularRequestsList.list} type={popularRequestsList.type} /> :
                        activeTab===STATISTIC_TAB_PRODUCT? 
                            <StatisticProductTable statisticProduct={statisticProduct} />: 
                            <StatisticCategoryTable statisticCategory={statisticCategory} />}
                    <label className="statistic__popular-label">
                        <input className="statistic__popular-checkbox" type='checkbox' checked={popularListIsOpen} onChange={() => setPopularListIsOpen(!popularListIsOpen)}/>
                        <span className="statistic__popular-text">Популярные запросы</span>
                    </label>
                    <Pagination currentPage={currentPage} pageMax={pagesNumber} handleGetData={handleGetData} count={count} popularListIsOpen={popularListIsOpen}/>
                </div>
            </div>
        </section>
    );
};

export default StatisticTab;