export const CHECK_USER_REQUEST = '/api/v1/auth/check/';
export const LOGOUT_REQUEST = '/api/v1/auth/logout/';
export const LOGIN_REQUEST = '/api/v1/auth/login/';
export const INVITE_USER_REQUEST = '/api/v1/user/invite/';
export const GET_USER_REQUEST = '/api/v1/user/';
export const GET_PRODUCT_SEARCH = '/api/v1/product/search/';
export const GET_PRODUCT_BY_ID = '/api/v1/product/';
export const GET_CATEGORIES = '/api/v1/category/';
export const REQUEST_INFO = '/api/v1/request/';
export const PRODUCT_INFO = '/api/v1/product/';
export const INTEGRATION_INFO = '/api/v1/integration/';
export const GET_INFO_FTS = '/api/v1/customer/fts/';
export const MAKE_CUSTOMER_REQUEST = '/api/v1/customer/';
export const GET_STATISTIC = '/api/v1/statistic/';
export const CHANGE_PASSWORD_REQUEST = '/api/v1/password/request/';
export const GET_STOCKS = '/api/v1/stock/';
export const CONFIGS_URL = '/api/v1/application/config/';
export const LOAD_PRODUCTS_URL = '/api/v1/application/product/load/';

export const getFetchRequest = (url, successCallback, errorCallback) => {
    const token = localStorage.getItem('token');
    const headers = {
        "Content-Type": "application/json", 
    };
    if(token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    fetch(`${url}`, {
        credentials: 'include',
        method: 'GET',
        headers,
    }).then(response => {
        return response.json();
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const postFetchRequest = (url, body, successCallback, errorCallback, withoutJSON=false) => {
    const token = localStorage.getItem('token');
    const headers = {
        "Content-Type": "application/json", 
    };
    if(token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    fetch(`${url}`, {
        credentials: 'include',
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        if(body&&!withoutJSON) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const postFetchLoadRequest = (url, body, successCallback, errorCallback) => {
    const token = localStorage.getItem('token');
    const headers = {};
    if(token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    fetch(`${url}`, {
        credentials: 'include',
        method: 'POST',
        headers,
        body,
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};
