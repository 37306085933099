import React, { useEffect, useState } from 'react';
import { INTEGRATION_INFO, getFetchRequest } from '../store/requests';
import { makeUrl, errorCallback, ONE_DAY } from '../constant';
import { ErrorHandler } from './ErrorHandler';
import { handleTransformDate } from './sharedComponents/helper';

const checkDays = (firstDate) => {
    return Math.round(Math.abs((new Date(firstDate) - new Date()) / ONE_DAY))
};

const durationUnitGenerator = (it) => {
    const text_forms = ['день','дня','дней']
    const n = Math.abs(it) % 100; 
    const n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

const IntegrationsTab = ({ isCollapsed }) => {
    const [integrationsList, setIntegrationsList] = useState([]);  

    const successGetIntegrationsCallback = (result) => {
        if(result.length) {
           setIntegrationsList(result);
        }
    };

    useEffect(() => {
        getFetchRequest(makeUrl([INTEGRATION_INFO]), successGetIntegrationsCallback, errorCallback);
    },[]);

    const handleGetAuthentification = (item) => {
        let params = '';
        for(let i=0; i<item.urlToGetCodeParams.length; i++) {
            params += i!==0 ? '&':'';
            params += item.urlToGetCodeParams[i]
        }
        const preparedUrl = item.urlToGetCode + `?${params}` 
        window.location.href = preparedUrl;
    }

    try {
        return (
            <section className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} 
                ${integrationsList.length?'':'integration_empty'}`}>
                {integrationsList.length ? integrationsList.map(it => (
                    <article key={it.name} className='integration' >
                        <h2 className='integration__title'>{it.name}</h2>
                        {it.dateExpired&&<p className='integration__description'>{handleTransformDate(it.dateExpired)}</p>}
                        {checkDays(it.dateExpired)<8&&<p className="alert alert__date">
                            До окончания действия токена осталось {checkDays(it.dateExpired)} {durationUnitGenerator(it.dateExpired)}</p>}
                        {it.urlToGetCode&&it.urlToGetCodeParams?<button className="button integration__button"
                            onClick={() => handleGetAuthentification(it)}>Получить код для аутентификации</button> 
                            : <p className='integration__description'>Дополнительная настройка не требуется </p>}
                    </article>
                )):<div className='integration__empty-page'>Ничего не найдено</div>}
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default IntegrationsTab;