import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { CollapseIcon } from '../appearence/icons/collapse_icon';
import { ExitIcon } from '../appearence/icons/exit_icon';
import { LogoIcon } from '../appearence/icons/logo_icon';
import { INTEGRATION_TAB, ORGANIZATIONS_TAB, PRODUCTS_TAB, REQUESTS_TAB, STATISTIC_TAB, STOCK_TAB, USERS_TAB, errorCallback, makeUrl} from "../constant";
import { LOGOUT_REQUEST, postFetchRequest } from '../store/requests';

const getTitleByLocation = (pathname) => {
    let result = '';
    if(pathname.includes(USERS_TAB)) {
        result = 'Пользователи';
    }
    if(pathname.includes(STATISTIC_TAB)) {
        result = 'Статистика';
    }
    if(pathname.includes(STOCK_TAB)) {
        result = 'Склады';
    }
    if(pathname.includes(ORGANIZATIONS_TAB)) {
        result = 'Организации';
    }
    if(pathname.includes(PRODUCTS_TAB)) {
        result = 'Продукты';
    }
    if(pathname.includes(INTEGRATION_TAB)) {
        result = 'Интеграции';
    }
    if(pathname.includes(REQUESTS_TAB)) {
        result = 'Запросы';
    }
    return result;
};

const Header = ({setIsCollapsed, isCollapsed, setAccessToken}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const succesCallback = (response) => {
        if(response.status === 200) {
            localStorage.removeItem("token");
            navigate("/login");
            setAccessToken('');
            return;
        }
    };

    const handleLogout = () => {
        postFetchRequest(makeUrl([LOGOUT_REQUEST]), '', succesCallback, errorCallback);
    };
    return (
        <header className="header">
            <div className="menu-common">
                <LogoIcon />
                <p className="menu-common__contacts">
                    8-800-775-02-42
                </p>
                <div className="menu-common__exit navigation-link" onClick={handleLogout}>
                    <ExitIcon />
                    <span className="menu-common__exit-text">Выход</span>
                </div>
            </div>
            <div className="info">
                <CollapseIcon nameOfClass={`info__collapse-icon ${isCollapsed ? 'info__collapse-icon_collapsed' : 'info__collapse-icon_full-width'} navigation-link`} setIsCollapsed={setIsCollapsed}/>
                <h3 className={`info__page-title  ${isCollapsed ? 'info__page-title_collapsed' : ''} navigation-link`} onClick={() => setIsCollapsed(!isCollapsed)}>{getTitleByLocation(location.pathname)}</h3>
            </div>
        </header>
    );
};

export default Header;