import React, { useState } from 'react';
import { CheckIcon } from '../appearence/icons/check_icon';
import { LinkIcon } from '../appearence/icons/link_icon';
import { errorCallback, makeUrl } from '../constant';
import { getFetchRequest, GET_PRODUCT_BY_ID, GET_PRODUCT_SEARCH, postFetchRequest } from '../store/requests';
import CategoriesList from './CategoriesList';

const PRODUCT_STATUS_REQUEST_TIME = 200;
const CATEGORY_ATTRIBUTE = "Category";

const makeListWithoutAttributes = (array) => {
    const result = array.filter(it => it.productPartNumber);
    return result;
};

const checkCategories = (selectedCategories) => {
    if (!selectedCategories || selectedCategories.length === 0) {
        return false;
    }
    return true;
};

const displayCategories = (productCategoriesList) => {
    let result = "";
    productCategoriesList.forEach((it) => {
        result += it.categoryName;
        result += " / ";
    });
    if (result.length > 0) {
        result = result.slice(0, -2);
    }
    return result;
}

const prepareCategories = (selectedCategories) => {
    let result = "";
    selectedCategories.forEach((it, index) => {
        result += it.categoryName;
        if (index < (selectedCategories.length - 1)) {
            result += "\n";
        }
    });
    return result;
};

const ProductsTab = ({ isCollapsed }) => {
    const [partNumber, setPartNumber] = useState('');
    const [changeProductFormIsOpen, setChangeProductFormIsOpen] = useState(false);
    const [listOfSearchResult, setListOfSearchResult] = useState([]);
    const [product, setProduct] = useState({});
    const [attributes, setAttributes] = useState({list:[], isOpen:false});
    const [images, setImages] = useState([]);
    const [newImage, setNewImage] = useState('');
    const [classifications, setClassifications] = useState({
            rohsStatus: "",
            moistureSensitiveLevel: "",
            reachStatus: "",
            eccn: "",
            htsus: "",
            californiaProp65: "",
        });
    const [classificationsIsOpen, setClassificationsIsOpen] = useState(false);
    const [alternatePackages, setAlternatePackages] = useState({list:[], isOpen:false});
    const [substitutes, setSubstitutes] = useState({list:[], isOpen:false});
    const [newItemInProduct, setNewItemInProduct] = useState({key: '', count:0});
    const [productCategoriesList, setProductCategoriesList] = useState([]);
    const [categoriesListIsOpen, setCategoriesListIsOpen] = useState(false);
    const [error, setError] = useState({type:'', message:''});
    const [manufacturer, setManufacturer] = useState('');
    let createProductTimeout = null;

    const successProductsListCallback = (response) => {
        if (response&& response.length) {
            setListOfSearchResult(makeListWithoutAttributes(response));
        } else {
            setListOfSearchResult([{ partNumber: 'Ничего не найдено' }]);
        }
    };

    const successGetProductCallback = (response) => {
        setProduct(response);
        getFetchRequest(makeUrl([GET_PRODUCT_BY_ID, response.id, '/category/']), (response) => {
            if(response && response.length > 0 && Array.isArray(response)) {
                setProductCategoriesList(response[0]);
            }
        }, errorCallback );
        response.attributes&&response.attributes.length&&setAttributes({list:response.attributes, isOpen: false});
        response.substitutes&&response.substitutes.length&&setSubstitutes({list:response.substitutes, isOpen: false});
        response.alternatePackages&&response.alternatePackages.length&&setAlternatePackages({list: response.alternatePackages, isOpen: false});
        response.classifications&&response.classifications.length&&setClassifications(response.classifications[0]);
        response.manufacturer&&setManufacturer(response.manufacturer.name);
        response.images&&response.images.length&&setImages(response.images);
        setListOfSearchResult([]);
        setChangeProductFormIsOpen(true);
    };

    const checkProductStatus = (processId) => {
        if(createProductTimeout) {
            clearTimeout(createProductTimeout);
        }
        createProductTimeout = setTimeout(() => {
            getFetchRequest(makeUrl([GET_PRODUCT_BY_ID, 'status/', processId, '/']), (response) => {
                if(!response.productId) {
                    checkProductStatus(processId);
                }
            }, errorCallback);
        }, PRODUCT_STATUS_REQUEST_TIME);
    };

    const successUpdateCallback = async (result) => {
        if(!product.id) {
            const response = await result.json();
            checkProductStatus(response.processId);
        } else {
            if (checkCategories(productCategoriesList)) {
                postFetchRequest(makeUrl([GET_PRODUCT_BY_ID, product.id, '/category/']), [productCategoriesList], () => {}, errorCallback );
            }
        }
        handleResetFields();
    };

    const handleSearchByPartName = (e) => {
        setPartNumber(e.target.value);
        if (e.target.value&&e.target.value.length>2) {
            const pathPartNumber = e.target.value;
            getFetchRequest(makeUrl([GET_PRODUCT_SEARCH, 'hint/',`?q=${pathPartNumber}`]), successProductsListCallback, errorCallback);
        }
    };

    const getProductInfo = (id) => {
        if(id) {
            handleResetFields();
            getFetchRequest(makeUrl([GET_PRODUCT_BY_ID, id, '/']), successGetProductCallback, errorCallback);
        }
    };

    const handleResetFields = () => {
        setProduct({});
        setPartNumber('');
        setAttributes({list:[], isOpen:false});
        setImages([]);
        setNewImage('');
        setClassifications({
                rohsStatus: "",
                moistureSensitiveLevel: "",
                reachStatus: "",
                eccn: "",
                htsus: "",
                californiaProp65: "",
            });
        setClassificationsIsOpen(false);
        setAlternatePackages({list:[], isOpen:false});
        setSubstitutes({list:[], isOpen:false});
        setNewItemInProduct({key: '', count:0});
        setProductCategoriesList([]);
        setCategoriesListIsOpen(false);
        setError({type:'', message:''});
        setChangeProductFormIsOpen(false);
    };

    const handleAddNewImage = () => {
        if (newImage.trim().length > 0) {
            const copiedArray = [...images];
            copiedArray.push({externalUrl : newImage});
            setImages(copiedArray);
            setNewImage("");
        }
    };

    const handleChangeProperty = (propertyName, e, index='', setObj='', obj='') => {
        if(propertyName==='partNumber'||propertyName==='description') {
            setProduct(product => ({ ...product, [`${propertyName}`]: e.target.value}));
        } else {
            if(index!=='') {
                if(propertyName==='images') {
                    const copiedArray = [...images];
                    copiedArray[index].externalUrl = e.target.value;
                    setImages(copiedArray);
                } else {
                    const arrayOfProperty = obj.list;
                    arrayOfProperty[index][propertyName] = e.target.value;
                    setObj(prev => ({...prev, list: arrayOfProperty, isChanged: true}));
                }
            }
        }
        
    };

    const handleAddNewProductProperty = () => {
        if(newItemInProduct.count===1 && newItemInProduct.altr.trim().length > 0) {
            const copiedArray = [...alternatePackages.list];
            copiedArray.push({mfrAlternatePack: newItemInProduct.altr});
            setAlternatePackages(prev=> ({...prev, list: copiedArray}));
        } else if(newItemInProduct.count===3) {
            if(!newItemInProduct.partNumber||!newItemInProduct.mfr||!newItemInProduct.type) {
                setError({type:'substitutes', message:'Заполните все поля'});
                return;
            }
            const copiedArray = [...substitutes.list];
            copiedArray.push({substitutePartNumber: newItemInProduct.partNumber, substituteManufacturer: newItemInProduct.mfr, substituteType: newItemInProduct.type});
            setSubstitutes(prev=> ({...prev, list: copiedArray}));
        } else {
            if(newItemInProduct.key==='attributes') {
                if(!newItemInProduct.name||!newItemInProduct.value) {
                    setError({type:'attributes', message:'Заполните все поля'});
                    return;
                }
                const copiedArray = [...attributes.list];
                copiedArray.push({attributeKey: newItemInProduct.name, attributeValue: newItemInProduct.value});
                setAttributes(prev=> ({...prev, list: copiedArray}));
            }
        }
        setNewItemInProduct({key: '', count: 0});
    };

    const handleUpdateProduct = () => {
        const data = {
            "partNumber": product.partNumber,
            "description": product.description,
            "images": images,
            "attributes": attributes.list,
            "substitutes": substitutes.list,
            "classifications": [classifications],
            "alternatePackages": alternatePackages.list,
        };
        if (manufacturer) {
            data.manufacturer = {name: manufacturer};
        }
        if (product.id) {
            postFetchRequest(makeUrl([GET_PRODUCT_BY_ID, product.id, '/']), data, successUpdateCallback, errorCallback, true );
        } else {
            if (data.attributes.find(it => it.attributeKey === CATEGORY_ATTRIBUTE)) {
                data.attributes.forEach(it => {
                    if(it.attributeKey === CATEGORY_ATTRIBUTE) {
                        it.attributeValue = prepareCategories(productCategoriesList);
                    }
                })
            } else {
                data.attributes.push({attributeKey: "Category", attributeValue: prepareCategories(productCategoriesList)});
            }
            postFetchRequest(makeUrl([GET_PRODUCT_BY_ID]), data, successUpdateCallback, errorCallback, true);
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        setCategoriesListIsOpen(false);
    };

    return (
        <>
            <section className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'}`} onClick={handleClick}>
                <article className="products">
                    <label className="products__label products__label-search">Изменить продукт
                        <input className="products__input"
                            value={partNumber}
                            placeholder="Поиск по парт-номеру"
                            onChange={(e) => handleSearchByPartName(e)} />
                    </label>
                    {listOfSearchResult.length ?
                        <ul className='products__search-list'>
                            <p className='products__label products__label-search'>Результаты поиска</p>
                                {listOfSearchResult.map((it, index) => (
                                    it.productPartNumber ? 
                                        <li className='products__search-list-item navigation-link' key={index + 'partNumber'} onClick={() => getProductInfo(it.productId)}
                                            dangerouslySetInnerHTML={{ __html: it.productPartNumber}}></li>
                                        :
                                        <li className='products__search-list-item navigation-link'>{it.partNumber}</li>
                                ))}
                        </ul>
                        : ''}
                    <p className='products__text'>или</p>
                    {changeProductFormIsOpen ?
                        <form>
                            <label className="products__label">Парт-номер
                                <input className="products__input" value={product.partNumber}
                                    name='partNumber' onChange={(e) => handleChangeProperty('partNumber', e)} />
                            </label>
                            <label className="products__label">Описание
                                <input className="products__input" value={product.description}
                                    name='description' onChange={(e) => handleChangeProperty('description', e)} />
                            </label>
                            <label className="products__label">Производитель
                                <input className="products__input" value={manufacturer}
                                       name='manufacturer' onChange={(e) => setManufacturer(e.target.value)}  />
                            </label>
                            <label className="products__label">Категории: {displayCategories(productCategoriesList)}</label>
                            <CategoriesList
                                productCategoriesList={productCategoriesList}
                                setProductCategoriesList={setProductCategoriesList}
                                categoriesListIsOpen={categoriesListIsOpen}
                                setCategoriesListIsOpen={setCategoriesListIsOpen}/>
                            {images.length ? images.map((it, index) => {
                                return (
                                    <label key={it.externalUrl + index + 'image'} className="products__label">Ссылка на изображение
                                        <input className="products__input" value={it.externalUrl} name='images' onChange={(e) => handleChangeProperty('images', e, index)} />
                                    </label>
                                );
                            }):''}
                            <label className="products__label">Ссылка на изображение
                                <input className="products__input" value={newImage} onChange={(e) => setNewImage(e.target.value)} />
                            </label>
                            <CheckIcon handleClick={handleAddNewImage} />
                            {attributes.list.length?<p className='products__label navigation-link' onClick={() => setAttributes({list: attributes.list, isOpen:!attributes.isOpen})}>
                                Показать все аттрибуты <LinkIcon nameOfClass="products__icon" /></p>:''}
                            {attributes.list.length&&attributes.isOpen ? 
                                attributes.list.map((it, index) => {
                                    return (
                                        <label key={it.attributeKey+index+'attr'} className="products__label">{it.attributeKey}
                                            <input className="products__input" value={it.attributeValue}
                                                name='attributes' onChange={(e) => handleChangeProperty('attributeValue', e, index, setAttributes, attributes)} />
                                        </label>
                                    );
                                })
                                : ''}
                            <label className="products__label">Добавить новый аттрибут
                                <input className="products__input" defaultValue={''} name='attributes' placeholder='Название аттрибута' onChange={(e) => setNewItemInProduct(prev=>({...prev, key:'attributes', name:e.target.value, count:2}))} />
                                <input className="products__input" defaultValue={''} name='attributes' placeholder='Значение аттрибута' onChange={(e) => setNewItemInProduct(prev=>({...prev, key:'attributes', value:e.target.value, count:2}))} />
                                {error.type==='attributes'? <p className='alert'>{error.message}</p>:''}
                            </label>
                            <CheckIcon handleClick={handleAddNewProductProperty} />
                            <p className='products__label navigation-link' onClick={() => setClassificationsIsOpen(!classificationsIsOpen)}>
                                Показать всю классификацию <LinkIcon nameOfClass="products__icon" /></p>
                            {classificationsIsOpen ?
                                <>
                                    <label key="rohsStatus" className="products__label">rohsStatus
                                        <input className="products__input" value={classifications.rohsStatus} onChange={(e) => setClassifications({...classifications, rohsStatus: e.target.value})} />
                                    </label>
                                    <label key="moistureSensitiveLevel" className="products__label">moistureSensitiveLevel
                                        <input className="products__input" value={classifications.moistureSensitiveLevel} onChange={(e) => setClassifications({...classifications, moistureSensitiveLevel: e.target.value})} />
                                    </label>
                                    <label key="reachStatus" className="products__label">reachStatus
                                        <input className="products__input" value={classifications.reachStatus} onChange={(e) => setClassifications({...classifications, reachStatus: e.target.value})} />
                                    </label>
                                    <label key="eccn" className="products__label">eccn
                                        <input className="products__input" value={classifications.eccn} onChange={(e) => setClassifications({...classifications, eccn: e.target.value})} />
                                    </label>
                                    <label key="htsus" className="products__label">htsus
                                        <input className="products__input" value={classifications.htsus} onChange={(e) => setClassifications({...classifications, htsus: e.target.value})} />
                                    </label>
                                    <label key="californiaProp65" className="products__label">californiaProp65
                                        <input className="products__input" value={classifications.californiaProp65} onChange={(e) => setClassifications({...classifications, californiaProp65: e.target.value})} />
                                    </label>
                                </>
                            :''}
                            {substitutes.list.length?<p className='products__label navigation-link' onClick={() => setSubstitutes({ list: substitutes.list, isOpen: !substitutes.isOpen })}>
                                Показать всe аналоги <LinkIcon nameOfClass="products__icon" /></p>:''}
                            {substitutes.list.length && substitutes.isOpen ?
                                substitutes.list.map((it, index) => {
                                    return (
                                        <label key={index + 'substitutes'} className="products__label">{it.substitutePartNumber}
                                            <input className="products__input" value={it.substitutePartNumber} onChange={(e) => handleChangeProperty('substitutePartNumber', e, index, setSubstitutes, substitutes)} />
                                            <input className="products__input" value={it.substituteManufacturer} onChange={(e) => handleChangeProperty('substituteManufacturer', e, index, setSubstitutes, substitutes)} />
                                            <input className="products__input" value={it.substituteType} onChange={(e) => handleChangeProperty('substituteType', e, index, setSubstitutes, substitutes)} />
                                        </label>
                                    );
                                })
                                : ''}
                            <label className="products__label">Добавить новый аналог
                                <input className="products__input" defaultValue={''} placeholder='Парт-номер' onChange={(e) => setNewItemInProduct(prev=>({...prev, key:'substitutes', partNumber:e.target.value, count:3}))} />
                                <input className="products__input" defaultValue={''} placeholder='Производитель' onChange={(e) => setNewItemInProduct(prev=>({...prev, key:'substitutes', mfr:e.target.value, count:3}))} />
                                <input className="products__input" defaultValue={''} placeholder='Тип' onChange={(e) => setNewItemInProduct(prev=>({...prev, key:'substitutes', type:e.target.value, count:3}))} />
                                {error.type==='substitutes'? <p className='alert'>{error.message}</p>:''}
                            </label>
                            <CheckIcon handleClick={handleAddNewProductProperty} />
                            {alternatePackages.list.length?<p className='products__label navigation-link' onClick={() => setAlternatePackages({ list: alternatePackages.list, isOpen: !alternatePackages.isOpen })}>
                                Показать всe альтернативные пакеты <LinkIcon nameOfClass="products__icon" /></p>:''}
                            {alternatePackages.list.length && alternatePackages.isOpen ?
                                alternatePackages.list.map((it, index) => {
                                    return (
                                        <label key={it.mfrAlternatePack+index+'alt'} className="products__label">
                                            <input className="products__input" value={it.mfrAlternatePack} onChange={(e) => handleChangeProperty('mfrAlternatePack', e, index, setAlternatePackages, alternatePackages)} />
                                        </label>
                                    );
                                })
                                : ''}
                            <label className="products__label">Добавить новый альтернативный пакет
                                <input className="products__input" defaultValue={''} placeholder='mfrAlternatePack' onChange={(e) => setNewItemInProduct(prev=>({...prev, key:'alternatePackages', altr:e.target.value, count:1}))} />
                            </label>
                            <CheckIcon handleClick={handleAddNewProductProperty} />
                        </form> : ''}
                    <button className="button products__button" type="button" onClick={() => changeProductFormIsOpen ? handleUpdateProduct()
                        : setChangeProductFormIsOpen(true)}>{changeProductFormIsOpen?'сохранить':'создать продукт'}</button>
                </article>
            </section>
        </>
    );
};

export default ProductsTab;