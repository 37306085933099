import React, {useState, useEffect} from 'react';
import {ADMIN_ROLE, CUSTOMER_ROLE, ELSI_API_URL, MANAGER_ROLE, errorCallback, makeUrl} from "../constant";
import { getFetchRequest, GET_USER_REQUEST, INVITE_USER_REQUEST, postFetchRequest, CHANGE_PASSWORD_REQUEST } from '../store/requests';
import CustomSelect from './customSelect';
import { SelectManagerModal } from './selectManagerModal';
import { CheckIconThin } from '../appearence/icons/check_icon_thin';

const selectContent = [
    {
        name: 'менеджер', 
        value: MANAGER_ROLE,
        id: MANAGER_ROLE,
    },
    {
        name: 'покупатель', 
        value: CUSTOMER_ROLE,
        id: CUSTOMER_ROLE,
    },
    {
        name: 'администратор', 
        value: ADMIN_ROLE,
        id: ADMIN_ROLE,
    },

];

const getRoleNameByValue = (value) => {
    let result = '';
    selectContent.forEach(it => it.value === value?result = it.name : null);
    return result;
};

const MainPageContent = ({ isCollapsed }) => {
    const [adminEmail, setAdminEmail] = useState('');
    const [userRole, setUserRole] = useState('');
    const [users, setUsers] = useState([]);
    const [managersList, setManagersLists] = useState([]);
    const [validatedUser, setValidatedUser] = useState({});
    const [selectManagerModalIsOpen, setSelectManagerModalIsOpen] = useState(false);

    const succesInvateCallback = (response) => {
        if(response) {
            setAdminEmail("");
        }
    };

    const succesLoadUserCallback = (response) => {
        setUsers(response);
    };

    const succesLoadManagersListCallback = (response) => {
        setManagersLists(response);
    }

    const succesValidateUserCallback = (response) => {
        if(response.status === 200) {
            loadUsers();
        }
    };

    const handleInvite = () => {
        if(adminEmail&&userRole) {
            const data = {email: adminEmail, elsiRole: userRole};
            postFetchRequest(makeUrl([INVITE_USER_REQUEST]), data, succesInvateCallback, errorCallback, true);
        }
    };
    const loadUsers = () => {
        getFetchRequest(makeUrl([GET_USER_REQUEST]), succesLoadUserCallback, errorCallback);
    };
    useEffect(() => {
        loadUsers();
    }, []);
    const handleSelectManager = (e, id) => {
        e.stopPropagation();
        getFetchRequest(makeUrl([GET_USER_REQUEST, 'search/?elsiRole='+MANAGER_ROLE]), succesLoadManagersListCallback, errorCallback);
        setValidatedUser({id, managerId:''});
        setSelectManagerModalIsOpen(true);
        
    };
    const handleValidateUser = () => {
        setSelectManagerModalIsOpen(false);
        const usersCopy = [...users];
        usersCopy.forEach(it => {
            if(it.id === validatedUser.id) {
                const currentValid = it.valid;
                if(!currentValid&&validatedUser.id&&validatedUser.managerId) {
                    const data = {managerId: validatedUser.managerId};
                    postFetchRequest(makeUrl([GET_USER_REQUEST, validatedUser.id, `/validate/`]), data, succesValidateUserCallback, errorCallback, true);
                    setValidatedUser({});
                }
            }
        });
    };

    const handleCloseModal = (e) => {
        e.stopPropagation();
        setSelectManagerModalIsOpen(false);
        setValidatedUser({});
    };

    const succesPasswordResetCallback = (response) => {
        console.log(response);
    };

    const handleSendResetPasswordRequest = (e, email) => {
        e.stopPropagation();
        if(email) {
            const data = {email};
            postFetchRequest(makeUrl([CHANGE_PASSWORD_REQUEST]), data, succesPasswordResetCallback, errorCallback, true);
        }
    };

    return (
        <>
            {selectManagerModalIsOpen?<SelectManagerModal managersList={managersList} validatedUser={validatedUser} 
                setValidatedUser={setValidatedUser} handleValidateUser={handleValidateUser}/>:''}
            <section className={`workspace ${isCollapsed===null ? '': isCollapsed? 'workspace_collapsed' : 'workspace_full-width'} workspace__main`} 
                onClick={(e) => handleCloseModal(e)}>
                <article className="invite-new-user">
                    <label className="invite-new-user__label">Email:
                        <input className="invite-new-user__input"
                            value={adminEmail}
                            onChange={(e)=> setAdminEmail(e.target.value.trim())}/>
                    </label>
                    <CustomSelect managersList={selectContent} inviteUser={true} setValidatedUser={setUserRole}/>
                    <button className="button invite-new-user__button" onClick={handleInvite}>Пригласить</button>
                </article>
                <article className="users">
                    <table className="table users__table">
                        <thead>
                            <tr className="table-header">
                                <th className="table-header__item">Имя</th>
                                <th className="table-header__item">Email</th>
                                <th className="table-header__item">Телефон</th>
                                <th className="table-header__item">Роль</th>
                                <th className="table-header__item">Подтвержден</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(it => {
                                return (
                                    <tr key={it.id} className="table-row">
                                        <td className={`table__item ${it.name ? "table__item_active" : ""}`}>{it.name}</td>
                                        <td className={`table__item ${it.email ? "table__item_active" : ""}`}>{it.email}</td>
                                        <td className={`table__item table__item_small ${it.phone ? "table__item_active" : ""}`}>{it.phone}</td>
                                        <td className={`table__item ${it.elsiRole ? "table__item_active" : ""}`}>{getRoleNameByValue(it.elsiRole)}</td>
                                        <td className="table__item table__item_small">
                                            <label className="users__label">
                                                <input type="checkbox" className="users__checkbox" checked={it.isValid} onChange={(e) => handleSelectManager(e, it.id)}/>
                                                <CheckIconThin nameOfClass="users__checkbox-icon" />
                                            </label>
                                        </td>
                                        <td><button disabled={!it.isValid} className={`button table__item-button ${it.isValid?'':'button_disabled'}`} 
                                            onClick={(e) => handleSendResetPasswordRequest(e, it.email)}>Сбросить пароль</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </article>
            </section>
        </>
    );
};

export default MainPageContent;