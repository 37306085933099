export const ELSI_API_URL = 'BACKEND_API_URL';

export const errorCallback = (error) => {
    console.log(error);
};

export const makeUrl = (params) => {
    const result = `${ELSI_API_URL}` + params.join('');
    return result;
};


export const USERS_TAB = 'users';
export const PRODUCTS_TAB = 'products';
export const REQUESTS_TAB = 'requests';
export const INTEGRATION_TAB = 'integration';
export const ORGANIZATIONS_TAB = 'organizations';
export const STATISTIC_TAB = 'statistic';
export const STOCK_TAB = 'stock';
export const CONFIGURATION_TAB = 'configuration';
export const PRODUCT_BASE_TAB = 'base';

export const STATISTIC_TAB_PRODUCT = 'product';
export const STATISTIC_TAB_CATEGORY = 'category';

export const MANAGER_ROLE = 'manager';
export const ADMIN_ROLE = 'admin';
export const CUSTOMER_ROLE = 'customer';

export const ONE_DAY = 24 * 60 * 60 * 1000;