import React from "react";

export const LogoIcon = () => {
    return (
        <svg className="menu-common__logo navigation-link" viewBox="0 0 261.25 40.95">
            <g id="a" />
            <g id="b">
                <g id="c">
                    <path className="d" d="M2.58,37.8v1.01c0,.75,.38,1.13,1.14,1.13h3.45v1.02H3.21c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.33-.15-.14-.28-.31-.37-.5-.09-.19-.14-.4-.14-.62v-4.03c0-.22,.04-.42,.14-.61,.09-.19,.21-.36,.37-.5,.15-.14,.33-.26,.53-.34,.2-.08,.4-.12,.61-.12h3.96v1.02H3.72c-.76,0-1.14,.38-1.14,1.13v.87H7.17v1.02H2.58Z" />
                    <path className="d" d="M32.85,39.93v1.02h-3.93c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.33-.15-.14-.28-.31-.37-.5-.09-.19-.14-.4-.14-.62v-5.6h1.02v5.04c0,.75,.38,1.13,1.14,1.13h3.42Z" />
                    <path className="d" d="M53.99,37.8v1.01c0,.75,.38,1.13,1.14,1.13h3.44v1.02h-3.96c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.33-.15-.14-.28-.31-.37-.5-.09-.19-.14-.4-.14-.62v-4.03c0-.22,.05-.42,.14-.61,.09-.19,.21-.36,.37-.5,.15-.14,.33-.26,.53-.34,.2-.08,.4-.12,.61-.12h3.96v1.02h-3.44c-.76,0-1.14,.38-1.14,1.13v.87h4.58v1.02h-4.58Z" />
                    <path className="d" d="M80.84,34.79c-.76,0-1.14,.38-1.14,1.13v2.9c0,.75,.38,1.13,1.14,1.13h3.44v1.02h-3.96c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.33-.15-.14-.28-.31-.37-.5-.09-.19-.14-.4-.14-.62v-4.03c0-.22,.05-.42,.14-.61,.09-.19,.22-.36,.37-.5,.15-.14,.33-.26,.53-.34,.2-.08,.4-.12,.61-.12h3.96v1.02h-3.44Z" />
                    <polygon className="d" points="104.4 33.77 104.4 34.79 106.69 34.79 106.69 40.95 107.71 40.95 107.71 34.79 110 34.79 110 33.77 104.4 33.77" />
                    <path className="d" d="M131.49,38.85c-.08-.08-.13-.15-.17-.22-.03-.06-.05-.15-.05-.27,0-.26,.06-.46,.19-.58,.13-.12,.27-.19,.44-.19h1.66c.76,0,1.14-.38,1.14-1.14v-.54c0-.75-.38-1.13-1.14-1.13h-2.43v6.16h-1.02v-7.18h3.96c.21,0,.41,.04,.61,.12,.2,.08,.37,.2,.53,.34,.15,.14,.28,.31,.37,.5,.09,.19,.14,.4,.14,.61v1.69c0,.22-.04,.42-.14,.62-.09,.2-.21,.36-.37,.5-.15,.14-.33,.25-.53,.33-.2,.08-.4,.12-.61,.12h-1.32l2.96,2.34h-1.54l-2.68-2.1Z" />
                    <path className="d" d="M160.41,35.91c0-.75-.38-1.13-1.13-1.13h-1.3c-.76,0-1.14,.38-1.14,1.13v2.9c0,.75,.38,1.13,1.14,1.13h1.3c.75,0,1.13-.38,1.13-1.13v-2.9Zm1.02,3.46c0,.22-.05,.43-.14,.62-.09,.19-.21,.36-.36,.5-.15,.14-.33,.25-.53,.33-.2,.08-.4,.12-.61,.12h-2.31c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.33-.15-.14-.28-.31-.37-.5-.09-.19-.14-.4-.14-.62v-4.03c0-.22,.05-.42,.14-.61,.09-.19,.22-.36,.37-.5,.15-.14,.33-.26,.53-.34,.2-.08,.4-.12,.61-.12h2.31c.21,0,.41,.04,.61,.12,.2,.08,.38,.2,.53,.34,.15,.14,.27,.31,.36,.5,.09,.19,.14,.4,.14,.61v4.03Z" />
                    <path className="d" d="M186.1,33.77h1.02v6.15c0,.28-.1,.53-.31,.73-.2,.2-.45,.31-.73,.31-.27,0-.47-.06-.61-.17-.13-.11-.25-.26-.35-.45l-2.56-5.63v6.25h-1.02v-6.14c0-.28,.1-.53,.3-.73,.2-.2,.45-.31,.73-.31s.47,.06,.61,.17c.14,.11,.25,.26,.35,.45l2.57,5.6v-6.22Z" />
                    <rect className="d" x="207.24" y="33.77" width="1.02" height="7.18" />
                    <path className="d" d="M230.52,34.79c-.76,0-1.14,.38-1.14,1.13v2.9c0,.75,.38,1.13,1.14,1.13h3.45v1.02h-3.96c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.33-.15-.14-.28-.31-.37-.5-.09-.19-.14-.4-.14-.62v-4.03c0-.22,.05-.42,.14-.61,.09-.19,.22-.36,.37-.5,.15-.14,.33-.26,.53-.34,.2-.08,.4-.12,.61-.12h3.96v1.02h-3.45Z" />
                    <path className="d" d="M256.09,34.79c-.14,0-.27,.03-.39,.08s-.23,.12-.32,.22c-.09,.09-.16,.2-.21,.32-.05,.12-.08,.25-.08,.39s.03,.27,.08,.39c.05,.12,.12,.22,.21,.32,.09,.09,.2,.16,.32,.21,.12,.05,.25,.08,.39,.08h1.94c.21,0,.41,.04,.61,.12,.2,.08,.38,.2,.53,.34s.27,.31,.36,.5c.09,.19,.14,.39,.14,.61v1.02c0,.22-.04,.43-.14,.62-.09,.19-.21,.36-.36,.5s-.33,.25-.53,.33c-.2,.08-.4,.12-.61,.12h-3.68v-1.02h3.24c.15,0,.29-.03,.42-.09,.13-.06,.24-.13,.34-.23,.1-.1,.17-.21,.23-.34,.06-.13,.08-.27,.08-.41s-.03-.29-.08-.42c-.06-.13-.13-.24-.23-.34s-.21-.17-.34-.23c-.13-.06-.27-.09-.42-.09h-1.87c-.21,0-.41-.04-.61-.12-.2-.08-.37-.19-.53-.34-.15-.14-.28-.31-.37-.51-.09-.2-.14-.4-.14-.61v-.88c0-.22,.05-.42,.14-.61,.09-.19,.21-.36,.37-.5,.15-.14,.33-.26,.53-.34,.2-.08,.4-.12,.61-.12h3.68v1.02h-3.31Z" />
                    <path className="e" d="M74.05,0h10.32V23.32h-30.5c-6.64,0-9.96-2.2-9.96-6.6V0h10.32V15.2c0,1.33,1.21,1.99,3.62,1.99h16.19V0Z" />
                    <path className="e" d="M114.56,6.13h-14.05v10.53l4.29-5.82h9.75c2.48,0,3.72-.66,3.72-1.99v-.73c0-1.33-1.24-1.99-3.72-1.99m13.99,5.08c0,3.63-2.71,5.59-8.12,5.87l10.01,6.24h-13.1l-10.95-6.24h-5.87v6.24h-10.27V0h28.72c2.03,0,3.67,.14,4.93,.42,1.26,.28,2.23,.69,2.91,1.23,.68,.54,1.14,1.23,1.39,2.07,.24,.84,.37,1.8,.37,2.88v4.61Z" />
                    <path className="e" d="M201.03,6.13h-14.05v10.53l4.29-5.82h9.75c2.48,0,3.72-.66,3.72-1.99v-.73c0-1.33-1.24-1.99-3.72-1.99m13.99,5.08c0,3.63-2.71,5.59-8.12,5.87l10.01,6.24h-13.1l-10.95-6.24h-5.87v6.24h-10.27V0h28.72c2.03,0,3.67,.14,4.93,.42,1.26,.28,2.23,.69,2.91,1.23,.68,.54,1.14,1.23,1.39,2.07,.24,.84,.37,1.8,.37,2.88v4.61Z" />
                    <path className="e" d="M142.81,8.12v7.08c0,1.26,1.21,1.89,3.62,1.89h12.53c2.45,0,3.67-.63,3.67-1.89v-7.08c0-1.33-1.22-1.99-3.67-1.99h-12.53c-2.41,0-3.62,.66-3.62,1.99M142.28,0h20.7c6.64,0,9.96,2.2,9.96,6.6v10.11c0,4.4-3.32,6.6-9.96,6.6h-20.7c-6.53,0-9.8-2.2-9.8-6.6V6.6C132.48,2.2,135.75,0,142.28,0" />
                    <path className="e" d="M239.5,8.18l6.5,8.91h-13.15l6.66-8.91ZM234,0l-16.09,23.32h24.74l3.98-5.35,3.97,5.35h10.65L244.95,0h-10.95Z" />
                    <path className="e" d="M21.59,8.18l6.5,8.91H14.94l6.66-8.91ZM16.09,0L0,23.32H24.74l3.98-5.35,3.97,5.35h10.65L27.04,0h-10.95Z" />
                </g></g>
        </svg>
    );
};