import React from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { CONFIGURATION_TAB, INTEGRATION_TAB, ORGANIZATIONS_TAB, PRODUCTS_TAB, PRODUCT_BASE_TAB, REQUESTS_TAB, STATISTIC_TAB, STOCK_TAB, USERS_TAB} from "../constant";
import { UserIcon } from '../appearence/icons/user_icon';
import { ProductsIcon } from '../appearence/icons/products_icon';
import { RequestsIcon } from '../appearence/icons/requests_icon';
import { GearIcon } from '../appearence/icons/gear_icon';
import { BuildingsIcon } from '../appearence/icons/buildings_icon';
import { StatisticIcon } from '../appearence/icons/statistic_icon';
import { StockIcon } from '../appearence/icons/stock_icon';
import { ConfigurationIcon } from '../appearence/icons/configuration_icon';
import { FoundationIcon } from '../appearence/icons/foundation_icon';

const LIST_OF_MENU_TABS = [
    {
        title: 'Пользователи',
        tabName: USERS_TAB,
        icon: UserIcon,
    },
    {
        title: 'Продукты',
        tabName: PRODUCTS_TAB,
        icon: ProductsIcon,
    },
    {
        title: 'Запросы',
        tabName: REQUESTS_TAB,
        icon: RequestsIcon,
    },
    {
        title: 'Интеграции',
        tabName: INTEGRATION_TAB,
        icon: GearIcon,
    },
    {
        title: 'Организации',
        tabName: ORGANIZATIONS_TAB,
        icon: BuildingsIcon,
    },
    {
        title: 'Статистика',
        tabName: STATISTIC_TAB,
        icon: StatisticIcon,
    },
    {
        title: 'Склады',
        tabName: STOCK_TAB,
        icon: StockIcon,
    },
    {
        title: 'Конфигурация',
        tabName: CONFIGURATION_TAB,
        icon: ConfigurationIcon,
    },
    // {
    //     title: 'База продуктов',
    //     tabName: PRODUCT_BASE_TAB,
    //     icon: FoundationIcon,
    // },
];

const MainPage = ({isCollapsed, setIsCollapsed}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleChangeTab = (tabName) => {
        navigate('/'+tabName);
        setIsCollapsed(null);
    };

    return (
        <nav className={`page-menu ${isCollapsed === null ? '': isCollapsed? 'page-menu_collapsed':'page-menu_full-width'}`}>
            <ul className={`menu__list ${isCollapsed === null ? '': isCollapsed? 'menu__list_collapsed':''}`}>
                {LIST_OF_MENU_TABS.map(it => (
                    <li key={it.title + it.tabName}
                        className={`menu__item ${isCollapsed === null ? '': isCollapsed? 'menu__item_collapsed':''} navigation-link`} 
                        onClick={()=>handleChangeTab(it.tabName)}>
                        <Link className="menu__item-link" to={'/'+it.tabName}>
                            {it.icon({nameOfClass: location.pathname.includes(it.tabName) ? 
                                `menu__icon menu__icon_active ${location.pathname.includes(REQUESTS_TAB) ? 'menu__icon-requests_active' : ''}`
                                :
                                `menu__icon ${it.tabName === REQUESTS_TAB ? 'menu__icon-requests' : ''}`
                            })}
                            <h2 className={`menu__title ${location.pathname.includes(it.tabName) ? 'menu__title_active':''} 
                                ${isCollapsed === null ? '': isCollapsed? 'menu__title_collapsed':'menu__title_full-width'}`}>
                                {it.title}
                            </h2>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default MainPage;
