import React, { useState, useEffect } from 'react';
import { errorCallback, makeUrl } from '../constant';
import { MAKE_CUSTOMER_REQUEST, getFetchRequest, GET_INFO_FTS, postFetchRequest } from '../store/requests';

const validateTin = (value, setError) => {
    let result = false;
    let tin = value;
    setError({ type: '', message: '' });
    if (typeof tin === 'number') {
        tin = tin.toString();
    } else if (typeof tin !== 'string') {
        tin = '';
    }

    if (!tin.length) {
        setError({ type: 'empty', message: 'ИНН пуст' });
    } else if (/[^0-9]/.test(tin)) {
        setError({ type: 'number', message: 'ИНН может состоять только из цифр' });
    } else if ([10, 12].indexOf(tin.length) === -1) {
        setError({ type: 'length', message: 'ИНН может состоять только из 10 или 12 цифр' });
    } else {
        const checkDigit = function (tin, coefficients) {
            var n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * tin[i];
            }
            return parseInt(n % 11 % 10);
        };
        switch (tin.length) {
        case 10:
            var n10 = checkDigit(tin, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(tin[9])) {
                result = true;
            }
            break;
        case 12:
            var n11 = checkDigit(tin, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            var n12 = checkDigit(tin, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if ((n11 === parseInt(tin[10])) && (n12 === parseInt(tin[11]))) {
                result = true;
            }
            break;
        }
        if (!result) {
            setError({ type: 'wrong', message: 'Неправильное ИНН' });
        }
    }
    return result;
};

const OrganizationsTab = ({isCollapsed}) => {
    const [error, setError] = useState({ type: '', message: '' });
    const [customersList, setCustomersList] = useState([]);
    const [activeTinIndex, setActiveTinIndex] = useState(0);

    const succesCustomerInfoCallback = (response) => {
        if(response.length) {
            setCustomersList(response);
        }
    };

    const succesCustomerByTinCallback = (response, index, tin) => {
        const data = {...customersList[index], tin, kpp: response.kpp, legalAddress: response.legal_address, name: response.name};
        setActiveTinIndex(0);
        postFetchRequest(makeUrl([MAKE_CUSTOMER_REQUEST, customersList[index].id, '/']), data, (response) => 
            saveCustomerCallback(response, index, data), errorCallback, true);
    };

    const saveCustomerCallback = (response, index, data) => {
        customersList[index] = data;
        setCustomersList([...customersList]);
    };

    useEffect(() => {
        getFetchRequest(makeUrl([MAKE_CUSTOMER_REQUEST]), succesCustomerInfoCallback, errorCallback);
    }, []);

    const handleFillTin = (e, index) => {
        setError({type: '', message: ''});
        setActiveTinIndex(index);
        if (validateTin(e.target.value, setError)) {
            const pathTin = e.target.value;
            getFetchRequest(makeUrl([GET_INFO_FTS, pathTin, '/']), (response) => succesCustomerByTinCallback(response, index, e.target.value), errorCallback);
        }
    };

    const handleChangeCustomerInfo = (e, index) => {
        customersList[index][e.target.name] = e.target.value;
        if (customersList[index].tin&&validateTin(customersList[index].tin, setError)) {
            const data = {...customersList[index]};
            postFetchRequest(makeUrl([MAKE_CUSTOMER_REQUEST, customersList[index].id, '/']), data, (response) => 
                saveCustomerCallback(response, index, data), errorCallback, true);
        }
    }

    return (
        <section className={`workspace organization__page ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'}`}> 
            <h1 className="organization__page-title">Организации</h1>
            {customersList.length ? 
                <table className="table orders__table">
                    <thead>
                        <tr className="table-header">
                            <th className="table-header__item">Название организации</th>
                            <th className="table-header__item">ИНН</th>
                            <th className="table-header__item">КПП</th>
                            <th className="table-header__item">Юридический адрес</th>
                            <th className="table-header__item">Ценовой коэффициент</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customersList.map((it, index) => {
                            return (
                                <tr key={it.tin} className="table-row">
                                    <td className={`table__item ${it.name?'table__item_active':''}`}>
                                        <input defaultValue={it.name} name="name" className="table-row__input"
                                            onChange={(e) => handleChangeCustomerInfo(e, index)}/>
                                    </td>
                                    <td className={`table__item ${it.tin?'table__item_active':''}`}>
                                        <input defaultValue={it.tin} name="tin" className="table-row__input"
                                            onChange={(e) => handleFillTin(e, index)}/>
                                            {error.type&&activeTinIndex===index? <p className='alert'>{error.message}</p> : ''}
                                    </td>
                                    <td className={`table__item ${it.kpp?'table__item_active':''}`}>
                                        <input defaultValue={it.kpp} name="kpp" className="table-row__input"
                                            onChange={(e) => handleChangeCustomerInfo(e, index)}/>
                                    </td>
                                    <td className={`table__item ${it.legalAddress?'table__item_active':''}`}>
                                        <input defaultValue={it.legalAddress} name="legalAddress" className="table-row__input"
                                            onChange={(e) => handleChangeCustomerInfo(e, index)}/>
                                    </td>
                                    <td className={`table__item ${it.priceCoefficient?'table__item_active':''}`}>
                                        <input defaultValue={it.priceCoefficient} name="priceCoefficient" className="table-row__input"
                                            onChange={(e) => handleChangeCustomerInfo(e, index)}/>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                :<div className='integration__empty-page'>Ничего не найдено</div>}
        </section>
    );
};

export default OrganizationsTab;