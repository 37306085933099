import React, { useState } from 'react';
import { ErrorHandler } from './ErrorHandler';
import { LOAD_PRODUCTS_URL, postFetchRequest } from '../store/requests';
import { errorCallback, makeUrl } from '../constant';

const BASE_PRODUCT_VALUE_LOAD = 'load';
const BASE_PRODUCT_VALUE_RELOAD = 'reload';
const BASE_PRODUCT_VALUE_DOWNLOAD = 'download';

const ProductBaseTab = ({ isCollapsed }) => {
    const [loadProductState, setLoadProductState] = useState('');
    const [fileData, setFileData] = useState('');

    const loadFileData = () => {
        let preparedBody = null;
        if(loadProductState === BASE_PRODUCT_VALUE_DOWNLOAD) {
            preparedBody = {fileName: fileData};
        }
        postFetchRequest(makeUrl([LOAD_PRODUCTS_URL, `?orUpdate=${loadProductState === BASE_PRODUCT_VALUE_LOAD ? 'false' : 'true'}`]), 
            preparedBody, () => {}, errorCallback);
        setFileData('');
    };

    try {
        return (
            <section className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} base__page`}>
                <div className="base__block">
                    <h1 className="config__title">База продуктов</h1>
                    <article className="base__article">
                        <label className="base__label">
                            <input type="radio" 
                                checked={loadProductState === BASE_PRODUCT_VALUE_LOAD} 
                                onChange={() => setLoadProductState(BASE_PRODUCT_VALUE_LOAD)}
                            />
                            Загрузить новые 
                        </label>
                        <label className="base__label">
                            <input type="radio" 
                                checked={loadProductState === BASE_PRODUCT_VALUE_RELOAD} 
                                onChange={() => setLoadProductState(BASE_PRODUCT_VALUE_RELOAD)}
                            />
                            Перезагрузить 
                        </label>
                        <label className="base__label">
                            <input type="radio" 
                                checked={loadProductState === BASE_PRODUCT_VALUE_DOWNLOAD} 
                                onChange={() => setLoadProductState(BASE_PRODUCT_VALUE_DOWNLOAD)}
                            />
                            Загрузить из файла  
                        </label>
                    </article>
                    <button type="button" className="button base__button" onClick={loadFileData}>
                        {loadProductState === BASE_PRODUCT_VALUE_RELOAD ? 'Перезагрузить' : 'Загрузить'}
                    </button>
                </div>
                {loadProductState === BASE_PRODUCT_VALUE_DOWNLOAD ? 
                    <label className="base__label_full-width">
                        <input 
                            placeholder="Введите название файла" 
                            className="base__input" value={fileData} 
                            onChange={(e) => setFileData(e.target.value)}
                        />
                    </label> 
                    : 
                    null}
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default ProductBaseTab;
