import React, { useEffect, useState } from 'react';
import { GET_STOCKS, getFetchRequest, postFetchLoadRequest, postFetchRequest } from '../store/requests';
import { makeUrl, errorCallback } from '../constant';
import { ErrorHandler } from './ErrorHandler';
import { PlusIcon } from '../appearence/icons/plus_icon';
import { MinusIcon } from '../appearence/icons/minus_icon';

const isDefaultStock = (stockCode) => {
    let result = false;
    if(stockCode === 'default_stock' || stockCode === 'mouser' || stockCode === 'digikey') {
        result = true;
    }
    return result;
};

const handleSortStocks = (a, b) => {
    if(isDefaultStock(a.code)&&a.code>b.code) return 1;
    if(isDefaultStock(a.code)&&a.code<b.code) return -1;
    if(isDefaultStock(a.code)) return -1;
    if(isDefaultStock(b.code)) return 1;
    if(a.code > b.code) return 1;
    if(a.code < b.code) return -1;
};

const StocksTab = ({ isCollapsed }) => {
    const [stocksList, setStocksList] = useState([]);
    const [addNewStockIsOpen, setAddNewStockIsOpen] = useState(false);
    const [newStockValue, setNewStockValue] = useState({ name: '', isActive: false });

    const successGetStocksCallback = (result) => {
        setStocksList(result);
    };

    useEffect(() => {
        getFetchRequest(makeUrl([GET_STOCKS]), successGetStocksCallback, errorCallback);
    }, []);

    const onFileUpload = (e, id) => {
        if (e.target.files && e.target.files[0]) {
            e.preventDefault();
            const file = e.target.files.item(0);
            const formData = new FormData();
            formData.append('file', file);
            postFetchLoadRequest(makeUrl([GET_STOCKS, id, '/price/']), formData, () => {}, errorCallback);
        }
    };

    const successAddStockCallback = (response) => {
        setStocksList([...stocksList, response]);
        setAddNewStockIsOpen(false);
        setNewStockValue({ name: '', code: '', isActive: false });
    };

    const handleAddStock = (e) => {
        e.preventDefault();
        if (newStockValue.name) {
            postFetchRequest(makeUrl([GET_STOCKS]), newStockValue, successAddStockCallback, errorCallback);
        }
    };

    const handleChangeStock = (e, index) => {
        const coppiedData = [...stocksList];
        coppiedData[index][e.target.name] = e.target.name==='isActive'?e.target.checked:e.target.value;
        postFetchRequest(makeUrl([GET_STOCKS, coppiedData[index].id, '/']), coppiedData[index], () => {}, errorCallback);
        setStocksList([...coppiedData]);
    };

    try {
        return (
            <section className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} stock__page`}>
                {stocksList.length ?
                    <>
                        <h1 className="requests-page__title">Склады</h1>
                        <table className="table stock__table">
                            <thead>
                                <tr className="table-header">
                                    <th className="table-header__item">Код</th>
                                    <th className="table-header__item">Название</th>
                                    <th className="table-header__item">Активен</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stocksList.sort(handleSortStocks).map((it, index) => {
                                    return (
                                        <tr key={it.id} className="table-row">
                                            <td className={`table__item ${it.code ? 'table__item_active' : ''}`}>{it.code}</td>
                                            <td className={`table__item ${it.name ? 'table__item_active' : ''}`}>
                                                <input className="stock__table-input" value = {it.name} onChange={(e) => handleChangeStock(e, index)} name="name"/>
                                            </td>
                                            <td className="table__item">
                                                <input type="checkbox" checked={it.isActive} name="isActive"
                                                    onChange={(e) => handleChangeStock(e, index)} />
                                            </td>
                                            <td><label className={`button table__item-button ${it.isActive ? '' : 'button_disabled'}`}>
                                                Загрузить цены
                                                <input type="file" disabled={!it.isActive} onChange={(e) => onFileUpload(e, it.id)} hidden/>
                                            </label>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </> : <div className='requests-page__empty'>Ничего не найдено</div>}
                <p className="stock__add" onClick={() => setAddNewStockIsOpen(!addNewStockIsOpen)}>
                    Добавить новый склад
                    {addNewStockIsOpen?<MinusIcon nameOfClass="stock__icon" />:<PlusIcon nameOfClass="stock__icon" />}
                </p>
                {addNewStockIsOpen ?
                    <form className="stock__form">
                        <label className="stock__form-label"> Название
                            <input className="stock__form-input" value={newStockValue.name} 
                                onChange={(e) => setNewStockValue({ ...newStockValue, name: e.target.value })} />
                        </label>
                        <label className="stock__form-label"> Активен
                            <input type="checkbox" checked={newStockValue.isActive} className="stock__form-checkbox"
                                onChange={(e) => setNewStockValue({ ...newStockValue, isActive: e.target.checked })} />
                        </label>
                        <button type="button" className="button stock__form-button" onClick={handleAddStock}>Добавить склад</button>
                    </form>: ''
                }
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default StocksTab;