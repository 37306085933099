import React from "react";
import CustomSelect from "./customSelect";

export const SelectManagerModal = ({managersList, setValidatedUser, handleValidateUser, validatedUser}) => {
    return (
        <div className="modal">
            <CustomSelect managersList={managersList} setValidatedUser={setValidatedUser} validatedUser={validatedUser} inviteUser={false}/>
            <button className="button modal__button" onClick={handleValidateUser}>Сохранить</button>
        </div>
    );
};