import React, { useState, useEffect } from 'react';
import MainPage from './components/MainPage';
import LoginPage from './components/LoginPage';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import MainPageContent from './components/MainPageContent';
import ProductsTab from './components/ProductsTab';
import IntegrationsTab from './components/IntegrationTab';
import OrganizationsTab from './components/OrganizationTab';
import StocksTab from './components/StocksTab';
import StatisticTab from './components/StatisticTab';
import RequestsTab from './components/RequestsTab';
import Header from './components/Header';
import { CHECK_USER_REQUEST, getFetchRequest } from './store/requests';
import { makeUrl } from './constant';
import ConfigurationTab from './components/ConfigurationTab';
import ProductBaseTab from './components/ProductBaseTab';


const App = () => {
    const [isCollapsed, setIsCollapsed] = useState(null);
    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState('');
    const succesCallback = (response) => {
        if(response && response.token) {
            setAccessToken(response.token);
            localStorage.setItem('token', response.token);
        } else {
            navigate("/login");
            return;
        }
    };

    const errorCallback = (e) => {
        console.log(e);
        navigate("/login");
        return;
    };

    useEffect(()=> {
        getFetchRequest(makeUrl([CHECK_USER_REQUEST]), succesCallback, errorCallback);
    },[]);

    if(!accessToken) {
        return (
            <Routes>
                <Route exact path="*" element={<LoginPage setAccessToken={setAccessToken}/>} />
            </Routes>
        );
    } else {
        return (
            <>
                <Header setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} setAccessToken={setAccessToken}/>
                <main className="main">
                    <MainPage isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                    <Routes> 
                        <Route exact path="/" element={<Navigate to="/users" replace />} />
                        <Route exact path="/users" element={<MainPageContent isCollapsed={isCollapsed}/>} />
                        <Route exact path="/products" element={<ProductsTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/integration" element={<IntegrationsTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/organizations" element={<OrganizationsTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/stock" element={<StocksTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/statistic" element={<StatisticTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/requests" element={<RequestsTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/configuration" element={<ConfigurationTab isCollapsed={isCollapsed}/>} />
                        <Route exact path="/base" element={<ProductBaseTab isCollapsed={isCollapsed}/>} />
                    </Routes>
                </main>
            </>
        );
    }
};

export default App;
